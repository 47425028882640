import React, {useState} from "react";
import styled from "styled-components";
import {Col, Container, Row} from "react-bootstrap";
import {rgba} from "polished";

import {Box, Button, Section, Text, Title} from "../../components/Core";
import {device} from "../../utils";
import {useTranslation} from "gatsby-plugin-react-i18next";


const SectionStyled = styled(Section)`
  position: relative;
  &::after {
    content: "";
    left: 0;
    bottom: 0;
    height: 30%;
    width: 100%;
    position: absolute;
    background: ${({theme}) => theme.colors.dark}!important;
  }
`;

const ULStyled = styled.ul`
  list-style: none;
  max-width: 350px;
  margin: 30px auto 0;
  padding-left: 0;

  @media ${device.sm} {
    display: flex;
    flex-wrap: wrap;
  }
  @media ${device.lg} {
    justify-content: space-between;
  }

  li {
    color: #19191b;
    font-size: 21px;
    font-weight: 300;
    letter-spacing: -0.66px;
    line-height: 50px;
    display: flex;
    margin-bottom: 5px;

    &:before {
      content: "\f00c";
      font-family: "Font Awesome 5 Free";
      font-weight: 900;
      display: inline-block;
      font-size: 13px;
      width: 30px;
      height: 30px;
      background-color: ${({theme}) => rgba(theme.colors.secondary, 0.1)};
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 500px;
      color: ${({theme}) => theme.colors.secondary};
      position: relative;
      top: 9px;
      margin-right: 13px;
    }
  }
`;

const CardPricing = styled(Box)`
  height: 100%;
  box-shadow: ${({theme}) =>
    `0 52px 54px ${rgba(theme.colors.shadow, 0.125)}`};

  border-bottom: 25px solid ${({theme}) => rgba(theme.colors.secondary, 1)};
  border-radius: 10px;
  background-color: #fff;
  padding-top: 30px;

  button {
    width: 100% !important;
    border-radius: 0 0 10px 10px !important;
  }
`;

const TitleSmall = styled.h4`
  color: ${({theme}) => theme.colors.secondary};
  font-size: 16px;
  font-weight: 300;
  letter-spacing: -0.5px;
  line-height: 28px;
`;

const Currency = styled.sup`
  color: ${({theme}) => theme.colors.dark};
  font-size: 20px;
  font-weight: 300;
  letter-spacing: -0.52px;
  line-height: 1;
  margin-bottom: 20px;
`;

const TimePer = styled.span`
  color: ${({theme}) => theme.colors.dark};
  font-size: 22px;
  font-weight: 700;
  letter-spacing: -0.52px;
  line-height: 1;
  margin-bottom: 13px;
`;

const ButtonGroup = styled.div`
  border-radius: 10px;
  padding: 5px;
  background-color: #f7f7fb;
  display: inline-flex;
  max-height: 65px;
  .btn {
    border-radius: 10px 0 0 10px;
    width: 160px;
    height: 45px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    color: #ffffff;
    font-size: 16px;
    font-weight: 700;
    letter-spacing: -0.5px;
    color: #19191b;
    &.active {
      background-color: ${({theme}) => theme.colors.secondary};
      color: #fff;
    }
    & + .btn {
      border-radius: 0 10px 10px 0;
    }
  }
`;

const Pricing = ({className}) => {

    const [timeMonthly, setTimeMonthly] = useState(true);
    const {t} = useTranslation('pricing');

    return (
        <>
            {/* <!-- Pricing section --> */}
            <SectionStyled className={className} bg="#F7F7FB" pt="90px !important" pb="0 !important">
                <Container
                    className="position-relative"
                    css={`z-index: 1;`}>
                    <Row className="justify-content-center">
                        <Col md="8" lg="9">
                            <div className="text-center mb-4">
                                <Title>{t('title_0')}</Title>
                                <Text className={"pb-4"}>
                                    {t('text_0')} {" "}<br className="d-none d-md-block"/>{t('text_1')}
                                </Text>
                            </div>
                        </Col>
                    </Row>
                    <div className="text-center">
                        <Row className="justify-content-center">
                            <Col lg="6" className="d-none">
                                <CardPricing>
                                    <div className="mb-4">
                                        <TitleSmall>{t('bloc_0_tagline')}</TitleSmall>
                                        <div className="d-flex align-items-end justify-content-center my-3">
                                            <Title
                                                css={`
                                                    font-size: 60px;
                                                    letter-spacing: -1.38px;
                                                    margin-bottom: 0 !important;
                                                  `}>
                                                {t('bloc_0_price')}
                                            </Title>
                                        </div>
                                        <Text fontSize="18px">&nbsp;</Text>
                                        <ULStyled>
                                            <li>{t('bloc_0_text_0')}</li>
                                            <li>{t('bloc_0_text_1')}</li>
                                            <li>{t('bloc_0_text_2')}</li>
                                            <li>{t('bloc_0_text_3')}</li>
                                        </ULStyled>
                                    </div>
                                    <Button className="d-none" bg="secondary">{t('bloc_0_button')}</Button>
                                </CardPricing>
                            </Col>
                            <Col lg="6">
                                <CardPricing>
                                    <div className="mb-4">
                                        <TitleSmall>{t('bloc_1_tagline')}</TitleSmall>
                                        <div className="d-flex align-items-end justify-content-center my-3">
                                            <Title
                                                css={`
                                                    font-size: 60px;
                                                    letter-spacing: -1.38px;
                                                    margin-bottom: 0 !important;
                                                  `}>
                                                {timeMonthly ? 1 : 14}
                                            </Title>
                                            <TimePer>.99</TimePer>
                                            <Currency>€</Currency>
                                        </div>

                                        {/*
                                        <Text fontSize="18px">{t(timeMonthly ? 'text_5' : 'text_5_bis')}</Text>
                                        */}

                                        <ButtonGroup className="mb-4 mt-3">
                                            <span
                                                role="button"
                                                className={`btn ${timeMonthly ? "btn-secondary text-white" : ""}`}
                                                onClick={() => {
                                                    setTimeMonthly(true);
                                                }}
                                                onKeyDown={() => {
                                                    setTimeMonthly(true);
                                                }}
                                                tabIndex={0}
                                            >
                                              {t('bloc_1_per_month')}
                                            </span>
                                            <span
                                                role="button"
                                                className={`btn ${!timeMonthly ? "btn-secondary text-white" : ""}`}
                                                onClick={() => {
                                                    setTimeMonthly(false);
                                                }}
                                                onKeyDown={() => {
                                                    setTimeMonthly(false);
                                                }}
                                                tabIndex={0}
                                            >
                                              {t('bloc_1_per_year')}
                                            </span>
                                        </ButtonGroup>

                                        <ULStyled>
                                            <li>{t('bloc_1_text_0')}</li>
                                            <li>{t('bloc_1_text_1')}</li>
                                            <li>{t('bloc_1_text_2')}</li>
                                            <li>{t('bloc_1_text_3')}</li>
                                        </ULStyled>
                                    </div>
                                    <Button className="d-none" bg="secondary">{t('bloc_1_button')}</Button>
                                </CardPricing>
                            </Col>
                        </Row>
                    </div>
                </Container>
            </SectionStyled>
        </>
    );
};

export default Pricing;
