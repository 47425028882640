import React from "react";
import styled from "styled-components";
import {Col, Container, Row} from "react-bootstrap";
import AppStoreButton from '../../components/AppStoreButton/AppStoreButton'
import {Box, Section, Text, Title} from "../../components/Core";

import {device} from "../../utils";
import imgL1HeroPhone from "../../assets/image/jpeg/features-screenshot.jpg";
import {useTranslation} from 'gatsby-plugin-react-i18next';
import TextLogo from "../../components/TextLogo/TextLogo";

const ImgRight = styled.img`
  max-width: 70%;
  @media ${device.sm} {
    max-width: 80%;
  }
  @media ${device.lg} {
    max-width: 90%;
  }
`;

const Hero = () => {

    const {t} = useTranslation('hero');

    return (
        <>
            {/* <!-- Hero Area --> */}
            <Section className="position-relative">
                <div className="pt-5"/>
                <Container>
                    <Row className="justify-content-center align-items-center">
                        <Col lg="5" md="8" sm="9" className="order-lg-2">
                            <div className="text-center text-lg-right position-relative">
                                <div
                                    className="img-main"
                                    data-aos="fade-down"
                                    data-aos-duration="750"
                                    data-aos-once="true"
                                    data-aos-delay="500"
                                >
                                    <ImgRight src={imgL1HeroPhone} alt=""/>
                                </div>
                            </div>
                        </Col>
                        <Col lg="6" className="order-lg-1">
                            <div
                                data-aos="fade-right"
                                data-aos-duration="750"
                                data-aos-once="true"
                                data-aos-delay="500"
                            >
                                <Box className="p-2 px-md-4">
                                    <TextLogo variant="hero" className="d-none d-md-block"/>

                                    <Title variant="small" className="text-center mt-4 mt-md-0 text-md-left">
                                        {t('title')}
                                    </Title>
                                    <Text variant="large" mb={4} className="text-center text-md-left">
                                        {t('text_0')}
                                    </Text>
                                    <Text mb={4} className="d-none text-center text-md-left">
                                        {t('text_1')}
                                    </Text>
                                    <div className="d-flex flex-column align-items-center align-items-md-start">
                                        <AppStoreButton/>
                                    </div>
                                </Box>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </Section>
        </>
    );
};

export default Hero;
