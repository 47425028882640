import React, {useState} from 'react';
import styled from "styled-components";
import {Alert, Container, Row, Col} from "react-bootstrap";
import addToMailchimp from 'gatsby-plugin-mailchimp'
import {useTranslation} from 'gatsby-plugin-react-i18next';

import {
    Title,
    Button,
    Section,
    Box,
    Text,
    Input,
} from "../../components/Core";

import imgOval from "../../assets/image/png/l1-cta-oval.png";
import svgCurve from "../../assets/image/svg/l1-curve-cta.svg";


const LeftCard = styled(Box)`
position: absolute;
top: 0;
left: 0px;
`;

const RightCard = styled(Box)`
position: absolute;
top: 0;
right: -275px;
`;


const Newsletter = () => {

    const [mailChimpResult, setMailChimpResult] = useState()
    const [email, setEmail] = useState("")
    const [name, setName] = useState("")

    const {t} = useTranslation('newsletter');

    const handleSubmit = async (e) => {
        e.preventDefault();
        const result = await addToMailchimp(email, {name: name, email: email})
        setMailChimpResult(result)

        if (result.result === 'success') {
            setEmail("")
            setName("")
        }
    }

    const handleChange = (e) => {
        if (e.target.name === 'email') {
            setEmail(e.target.value)
        }
        if (e.target.name === 'name') {
            setName(e.target.value)
        }
    }

    const alertMessage = () => {

        let level = 'danger';
        let message = t('text-input-result-error');

        if (typeof mailChimpResult === 'undefined') {
            return null;
        }

        if (mailChimpResult.result === 'success') {
            level = 'success';
            message = t('text-input-result-ok')
        } else {
            if (mailChimpResult.msg.includes('already subscribed')) {
                level = 'warning';
                message = t('text-result-already-subscribed')
            }
        }

        return (
            <Alert variant={level}>
                {message}
            </Alert>
        )
    }

    return (
        <>
            <Section bg="dark" className="position-relative">
                <LeftCard
                    data-aos="fade-right"
                    data-aos-duration="750"
                    data-aos-once="true"
                >
                    <img src={imgOval} alt="" className="img-fluid"/>
                </LeftCard>
                <RightCard>
                    <img src={svgCurve} alt="" className="img-fluid"/>
                </RightCard>
                <Container>
                    <Row className="justify-content-center">
                        <Col lg="7" xl="6">
                            <Box mb={5} className="text-center">
                                <Title color="light">{t('title')}</Title>
                                <Text color="light" opacity={0.7} className="d-none">
                                    {t('subtitle')}
                                </Text>
                            </Box>
                            {alertMessage()}
                            <form onSubmit={handleSubmit}>
                                <Box mb={3}>
                                    <Input type="name"
                                           name="name"
                                           value={name}
                                           onChange={handleChange}
                                           placeholder={t('text-input-name')}/>
                                </Box>
                                <Box mb={3}>
                                    <Input type="email"
                                           name="email"
                                           value={email}
                                           onChange={handleChange}
                                           placeholder={t('text-input-email')}/>
                                </Box>
                                <Button width="100%" type="submit" borderRadius={10}>
                                    {t('text-input-submit')}
                                </Button>
                            </form>
                        </Col>
                    </Row>
                </Container>
            </Section>
        </>
    );
};

export default Newsletter;
