import React from "react";
import styled from "styled-components";
import {Col, Container, Row} from "react-bootstrap";
import Slider from "react-slick";
import {rgba} from "polished";

import {Box, Section, Text, Title} from "../../components/Core";
import {breakpoints, device} from "../../utils";
import imgQuote from "../../assets/image/svg/quote-right-solid.svg";
import {useTranslation} from "gatsby-plugin-react-i18next";

const SliderStyled = styled(Slider)`
  position: relative;
  .slick-arrow {
    position: absolute;
    top: 50%;
    font-size: 0;
    height: 65px;
    display: flex;
    width: 65px;
    background: ${({theme}) => theme.colors.secondary};
    transition: 0.4s;
    border-radius: 500px;
    transform: translate(-50%, -50%);
    z-index: 999;
    margin-top: -20px;
    &::before {
      position: absolute;
      content: "";
      font-family: "Font Awesome 5 Free";
      font-weight: 900;
      font-size: 20px;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: #fff;
      transition: 0.4s;
    }
  }
  .slick-arrow.slick-prev {
    left: 0;
    &:before {
      content: "\f060";
    }
    @media ${device.md} {
      margin-left: 60px;
    }
  }

  .slick-arrow.slick-next {
    right: 0;
    left: auto;
    transform: translate(50%, -50%);
    &:before {
      content: "\f061";
    }
    @media ${device.md} {
      margin-right: 60px;
    }
  }

  .slick-arrow:hover,
  .slick-arrow:focus {
    background: ${({theme}) => theme.colors.light}!important;
    color: ${({theme}) => theme.colors.secondary}!important;
    &:before {
      color: ${({theme}) => theme.colors.secondary}!important;
    }
  }
`;

const SliderItem = styled(Box)`
  &:focus {
    outline: none;
  }
`;

const SliderCard = styled(Box)`
  border-radius: 10px;
  background: #fff;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  padding: 20px;
  @media ${device.sm} {
    padding: 35px 35px 20px 35px;
  }
  @media ${device.md} {
    padding: 10px;
    flex-direction: row;
  }
  @media ${device.lg} {
    margin: 60px 60px 100px 60px !important;
    box-shadow: ${({theme}) => `0 52px 54px ${rgba(theme.colors.shadow, 0.125)}`};
  }
`;

const SliderImgContainer = styled(Box)`
  width: 100%;
  border-radius: 10px;
  overflow: hidden;

  padding: 0 30px;

  @media ${device.sm} {
    min-width: 315px;
    padding: 0;
  }
  @media ${device.md} {
  }

  img {
    margin: 0 auto;
    max-width: unset;
    width: 100%;
  }
`;

const SliderText = styled(Box)`
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: auto;
  padding: 50px 30px 0px;
  @media ${device.md} {
    padding: 20px 50px 20px;
  }
  @media ${device.lg} {
    padding: 30px 70px 20px;
  }
`;
const SliderQuoteShape = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  width: 80px;
  @media ${device.md} {
    padding-right: 30px;
    margin-bottom: 30px;
  }
`;

const Testimonial = () => {

    const {t} = useTranslation('testimonial')

    const slickSettings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,
        responsive: [
            {
                breakpoint: breakpoints.lg,
                settings: {
                    arrows: false,
                },
            },
        ],
    };

    return (
        <>
            {/* <!-- testimonial section --> */}
            <Section pb={["50px!important"]}>
                <Container>
                    <Row className="justify-content-center mb-1">
                        <Col lg="6" md="9">
                            <div className="text-center">
                                <Title>{t('title')}</Title>
                                <Text className="d-none">
                                    {t('sub-title')}
                                </Text>
                            </div>
                        </Col>
                    </Row>
                    <Row className="justify-content-center">
                        <Col lg="12" xl="8">
                            <SliderStyled {...slickSettings}>
                                <SliderItem>
                                    <SliderCard>
                                        <SliderText>
                                            <SliderQuoteShape>
                                                <img src={imgQuote} alt="" className="img-fluid"/>
                                            </SliderQuoteShape>
                                            <Text color="dark" my={2}>{t('testimonial-1')}</Text>
                                            <Title variant="card" mb={0} mt={3}>
                                                Samantha
                                            </Title>
                                        </SliderText>
                                    </SliderCard>
                                </SliderItem>
                                <SliderItem>
                                    <SliderCard>
                                        <SliderText>
                                            <SliderQuoteShape>
                                                <img src={imgQuote} alt="" className="img-fluid"/>
                                            </SliderQuoteShape>
                                            <Text color="dark" my={2}>{t('testimonial-2')}</Text>
                                            <Title variant="card" mb={0} mt={3}>
                                                Rebecca
                                            </Title>
                                        </SliderText>
                                    </SliderCard>
                                </SliderItem>
                            </SliderStyled>
                        </Col>
                    </Row>
                </Container>
            </Section>
        </>
    );
};

export default Testimonial;
