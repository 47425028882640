import React from "react";
import styled from "styled-components";
import {useTranslation} from 'gatsby-plugin-react-i18next';

const Button = styled.a`
  display: flex;
  justify-content: start;
  align-items: center;
  border-radius: 50px;
  border: none;
  padding: 13px 40px 20px 35px;
  & {
    :hover, :focus, :active {
      color: white;
    }
  }
  & .fa-apple {
    font-size: 48px;
    margin-top: 5px;
    margin-right: 20px;
  }
  & div {
    text-align: left;
    span {
      display: block;
      font-size: 15px;
      line-height: 21px;
      font-weight: bold;
      font-size: 25px;
    }
  }
`;

const AppStoreButton = () => {

    const {t} = useTranslation('common')

    const url = "https://apps.apple.com/us/app/id1494001748"

    return (
        <Button className="text-white bg-dark" href={url}>
            <i className="fab fa-apple"/>
            <div>
                <small>{t('appstore-button-text-1')}</small>
                <span className="uppercase">{t('appstore-button-text-2')}</span>
            </div>
        </Button>
    );
};

export default AppStoreButton;
