import React from "react";
import Hero from "../sections/flashflash/Hero";
import Feature from "../sections/flashflash/Feature";
import Testimonial from "../sections/flashflash/Testimonial";
import PageWrapper from "../components/PageWrapper";
import Newsletter from "../components/Newsletter";
import Faq from "../sections/flashflash/Faq";
import Pricing from "../sections/flashflash/Pricing";
import {graphql} from 'gatsby';

const IndexPage = () => {
    return (
        <>
            <PageWrapper footerDark>
                <Hero/>
                <Feature/>
                <Pricing/>
                <Faq/>
                <Testimonial/>
                <Newsletter/>
            </PageWrapper>
        </>
    );
};

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {ns: {in: ["common", "contact", "faq", "feature", "header", "hero", "newsletter", "pricing", "testimonial"]}, language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

export default IndexPage;
