import React from "react";
import {rgba} from "polished";
import {Col, Container, Row} from "react-bootstrap";

import {Box, Section, Text, Title} from "../../components/Core";
import imgL1FeatureOval from "../../assets/image/png/l1-feature-oval.png";
import styled from "styled-components";
import {useTranslation} from "gatsby-plugin-react-i18next";

const ShapeTopRight = styled(Box)`
  position: absolute;
  top: 0;
  right: 0px;
`;

const FeatureCard = ({
                         color = "primary",
                         iconName,
                         title,
                         children,
                         ...rest
                     }) => (
    <Box width={"100%"} height={"100%"} bg="light" p="30px" borderRadius={10} {...rest}>
        <div className="d-flex justify-content-between align-items-start">
            <Box
                size={69}
                minWidth={69}
                minHeight={69}
                borderRadius="50%"
                color={color}
                fontSize="28px"
                className="d-flex justify-content-center align-items-center"
                mr="20px"
                css={`
          background-color: ${({theme, color}) =>
                    rgba(theme.colors[color], 0.1)};
        `}
            >
                <i className={`icon ${iconName}`}/>
            </Box>
            <div>
                <Text
                    color="heading"
                    as="h3"
                    fontSize={4}
                    fontWeight={500}
                    letterSpacing={-0.75}
                    mb={2}>
                    {title}
                </Text>
                <Text fontSize={2} lineHeight={1.75}>
                    {children}
                </Text>
            </div>
        </div>
    </Box>
);


const Feature2 = () => {

    const {t} = useTranslation('feature');

    return (
        <>
            <Section bg="primary" className="position-relative">
                <ShapeTopRight
                    data-aos="fade-left"
                    data-aos-duration="750"
                    data-aos-once="true"
                >
                    <img src={imgL1FeatureOval} alt="" className="img-fluid"/>
                </ShapeTopRight>
                <Container>
                    <Row className="justify-content-center">
                        <Col lg="9">
                            <Box className="text-center" mb={[4, 5]}>
                                <Title color="light">{t('title')}</Title>
                            </Box>
                        </Col>
                    </Row>
                    <Row className="justify-content-center">
                        <Col
                            lg="5"
                            md="6"
                            className="mb-4"
                            data-aos="zoom-in"
                            data-aos-duration="750"
                            data-aos-once="true"
                            data-aos-delay="50"
                        >
                            <FeatureCard
                                color="ash"
                                iconName="fa fa-tshirt text-info"
                                title={t('feature-1-title')}
                            >
                                {t('feature-1-text')}
                            </FeatureCard>
                        </Col>
                        <Col
                            lg="5"
                            md="6"
                            className="mb-4"
                            data-aos="zoom-in"
                            data-aos-duration="750"
                            data-aos-once="true"
                            data-aos-delay="50"
                        >
                            <FeatureCard
                                color="dark"
                                iconName="fa fa-heart text-danger"
                                title={t('feature-3-title')}
                            >
                                {t('feature-3-text')}
                            </FeatureCard>
                        </Col>
                    </Row>
                    <Row className="justify-content-center">
                        <Col
                            lg="5"
                            md="6"
                            className="mb-4"
                            data-aos="zoom-in"
                            data-aos-duration="750"
                            data-aos-once="true"
                            data-aos-delay="50"
                        >
                            <FeatureCard
                                color="ash"
                                iconName="fa fa-location-arrow text-success"
                                title={t('feature-2-title')}
                            >
                                {t('feature-2-text')}
                            </FeatureCard>
                        </Col>
                        <Col
                            lg="5"
                            md="6"
                            className="mb-4"
                            data-aos="zoom-in"
                            data-aos-duration="750"
                            data-aos-once="true"
                            data-aos-delay="50"
                        >
                            <FeatureCard
                                color="dark"
                                iconName="fa fa-hand-peace text-warning"
                                title={t('feature-4-title')}
                            >
                                {t('feature-4-text')}
                            </FeatureCard>
                        </Col>
                    </Row>
                </Container>
            </Section>
        </>
    );
}

export default Feature2;
