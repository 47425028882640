import React from "react";
import styled from "styled-components";
import {Col, Container, Row} from "react-bootstrap";
import {Box, Section, Text, Title} from "../../components/Core";
import {useTranslation} from "gatsby-plugin-react-i18next";
import {Link} from "gatsby";

const SectionStyled = styled(Section)``;

const FaqCard = ({title, children, ...rest}) => (
    <Box {...rest}>
        <Title variant="card" mb={4} fontSize="24px" fontWeight="900" color="light">
            {title}
        </Title>
        <Text variant="small" color="lightShade">
            {children}
        </Text>
    </Box>
);

const Faq = ({className}) => {

    const {t} = useTranslation('faq');

    return (
        <>
            <SectionStyled className={className} bg="dark">
                <Container>
                    <Row className="justify-content-center">
                        <Col xl="7" lg="8" md="9">
                            <div className="text-center">
                                <Title color="light">
                                    {t('title')}
                                </Title>
                                {/*
                                <Text color="lightShade">
                                    {t('subtitle')}
                                </Text>
                                */}
                            </div>
                        </Col>
                    </Row>
                    <Row className="pt-4 text-center">
                        <Col lg="6" className="mt-5">
                            <FaqCard title={t('question_0_title')}>
                                {t('question_0_text')}
                            </FaqCard>
                        </Col>
                        <Col lg="6" className="mt-5">
                            <FaqCard title={t('question_1_title')}>
                                {t('question_1_text')}
                            </FaqCard>
                        </Col>
                        <Col lg="6" className="mt-5">
                            <FaqCard title={t('question_2_title')}>
                                {t('question_2_text')}
                            </FaqCard>
                        </Col>
                        <Col lg="6" className="mt-5">
                            <FaqCard title={t('question_3_title')}>
                                {t('question_3_text')}
                            </FaqCard>
                        </Col>
                        <Col lg="6" className="mt-5">
                            <FaqCard title={t('question_4_title')}>
                                {t('question_4_text')}
                            </FaqCard>
                        </Col>
                        <Col lg="6" className="mt-5">
                            <FaqCard title={t('question_5_title')}>
                                {t('question_5_text')}
                            </FaqCard>
                        </Col>
                        <Col lg="12" className="d-none">
                            <Box className="text-center" pt={5}>
                                <h4>
                                    <Text css={`color: ${({theme}) => theme.colors.light} !important;`}>
                                        {t('footer_text_0')}{" "}
                                    </Text>
                                </h4>
                                <h5>
                                    <Text>
                                        <Link
                                            to="/contact"
                                            css={`color: ${({theme}) => theme.colors.secondary} !important;`}
                                        >
                                            {t('footer_text_1')}
                                        </Link>
                                    </Text>
                                </h5>
                            </Box>
                        </Col>
                    </Row>
                </Container>
            </SectionStyled>
        </>
    )
}


export default Faq;
